import { NgForm } from '@angular/forms'
import { HttpErrorResponse } from '@angular/common/http'
import { ToastrService } from 'ngx-toastr'
import moment from 'moment'
import { vsprintf } from 'sprintf-js'

const MongoFilters = {
  contains: '%s=/.*%s.*/i',
  notcontains: '%s=!/.*%s.*/i',
  startswith: '%s=/^%s/i',
  endswith: '%s=/%s$/i',
  '=': '%s=%s',
  '<>': '%s=!%s',
  '<': '%s<%s',
  '>': '%s>%s',
  '<=': '%s<=%s',
  '>=': '%s>=%s',
}

export class Utils {
  static parseError(toast: ToastrService, errorResponse?: HttpErrorResponse | string) {
    try {
      // Check response type
      if (typeof errorResponse === 'string') {
        toast.error(errorResponse, 'Errore')
        return
      }

      // Check unknown error
      if (errorResponse.status === 0) {
        toast.error(
          'Ops, ci sono problemi nella connessione. Controlla lo stato della tua linea e riprova.',
          'Errore',
        )
        return
      }

      // Check authentication error
      if (errorResponse.status === 400 && errorResponse.error.error === 'CREDENTIALS_NOT_VALID') {
        toast.error('Ops, password errata', 'Errore')
        return
      }

      if (errorResponse.status === 404 && errorResponse.error.error === 'CREDENTIALS_NOT_VALID') {
        toast.error('Ops, username errato', 'Errore')
        return
      }

      if (
        errorResponse.status === 409 &&
        errorResponse.error.message === 'There is already an active session.'
      ) {
        return
      }

      toast.error(
        errorResponse.error.message,
        `Errore ${errorResponse.status}: ${errorResponse.error.error}`,
      )
    } catch (error) {
      console.error(error)
    }
  }

  static parseLoadOptions(options: any) {
    const params = {}

    // Check if totalCount is required
    if (options.requireTotalCount) {
      params['client'] = 'table'
    }

    // Paging params
    if (options.skip) {
      params['offset'] = options.skip
    }

    if (options.take) {
      params['limit'] = options.take
    }

    // Sort params
    if (options.sort) {
      params['sort'] = [
        options.sort[0]['desc'] ? '-' + options.sort[0]['selector'] : options.sort[0]['selector'],
      ]
    }

    // Filters
    if (options.filter) {
      // Check if there is a global search
      const globalSearch = this.parseGlobalSearch(options.filter)
      if (globalSearch !== false && globalSearch !== '') {
        params['q'] = `/.*${globalSearch}.*/i`
      }

      const filters = this.destructFilters(options.filter)
      const query = []

      // Here parse all filters
      for (const filter of Object.keys(filters)) {
        for (const compare of Object.keys(filters[filter])) {
          if (compare === '=' && filters[filter][compare].length > 1) {
            query.push(filter + compare + filters[filter][compare].join(','))
          } else {
            let [filterValue] = filters[filter][compare]

            if (
              filterValue &&
              typeof filterValue !== 'object' &&
              filterValue.toString().indexOf('::') !== -1
            ) {
              continue
            }

            // Check date object
            if (typeof filterValue === 'object' && moment.isDate(filterValue)) {
              filterValue = filterValue.toISOString()
            }

            // Check string filters
            if (
              typeof filterValue === 'string' &&
              filterValue !== 'null' &&
              filterValue !== '!null' &&
              (compare === '=' || compare === '<>')
            ) {
              filterValue = `"${filterValue}"`
            }

            filterValue === undefined
              ? query.push('!' + filter)
              : query.push(vsprintf(MongoFilters[compare], [filter, filterValue]))
          }
        }
      }

      query.length > 0 && (params['filters'] = query.map(qy => encodeURIComponent(qy)).join('&'))
    }

    // Search params
    if (options.searchValue) {
      const globalSearch = options.filter
        ? this.parseGlobalSearch(options.filter)
        : options.searchValue
      params['q'] = `/.*${globalSearch}.*/i`
    }

    return params
  }

  static destructFilters(object: any, filters = []) {
    const [obj1, compare, obj2] = object

    // Check if single or multi objects
    if (compare !== 'and' && compare !== 'or') {
      if (!filters[obj1]) {
        filters[obj1] = []
      }

      if (!filters[obj1][compare]) {
        filters[obj1][compare] = []
      }

      filters[obj1][compare].push(obj2)
    } else {
      // Parse all sub objects
      for (const subobj of object) {
        if (subobj instanceof Array) {
          filters = this.destructFilters(subobj, filters)
        }
      }
    }

    return filters
  }

  static parseGlobalSearch(filters): boolean | string {
    let globalSearch: boolean | string = false

    if (
      filters instanceof Array &&
      typeof filters[2] === 'string' &&
      filters[2].indexOf('::') !== -1
    ) {
      globalSearch = filters[2].match('::(.*)::')[1]
    } else if (filters !== undefined) {
      filters.forEach(filter => {
        if (filter instanceof Array) {
          if (typeof filter[2] === 'string' && filter[2].indexOf('::') !== -1) {
            globalSearch = filter[2].match('::(.*)::')[1]
          } else {
            filter.forEach(subfilter => {
              if (
                subfilter &&
                typeof subfilter[2] === 'string' &&
                subfilter[2].indexOf('::') !== -1
              ) {
                globalSearch = subfilter[2].match('::(.*)::')[1]
              }
            })
          }
        }
      })
    }

    return globalSearch
  }

  static checkForm(form: NgForm): boolean {
    if (!form) {
      return true
    }

    Object.keys(form.controls).forEach(field => {
      const control = form.controls[field]
      control.markAsTouched({ onlySelf: true })
    })

    return !form.invalid
  }

  static pushState(base: string, state: string) {
    const hash = window.location.hash.split('/').filter(st => st !== '')
    hash.push(base + '/' + state)
    window.location.hash = hash.join('/')
  }

  static popState() {
    const hash = window.location.hash.split('/').filter(st => st !== '')
    hash.pop()
    hash.pop()
    window.location.hash = hash.join('/')
  }

  static setNewState(state: string) {
    const hash = window.location.hash.split('/').filter(st => st !== '')
    hash.pop()
    hash.push(state)
    window.location.hash = hash.join('/')
  }

  static getState(base: string) {
    const state = {}
    const path = window.location.hash
      .substring(1)
      .split('/')
      .filter(st => st !== '')

    for (const count of Object.keys(path)) {
      if (+count % 2 > 0) {
        state[path[+count - 1]] = path[+count]
      }
    }

    return state[base]
  }

  static slugify(text: string, trim = true) {
    text = trim ? text.replace(/^\s+|\s+$/g, '') : text // trim
    text = text.toLowerCase()

    // remove accents, swap ñ for n, etc
    const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
    const to = 'aaaaaaeeeeiiiioooouuuunc--_---'

    for (let i = 0, l = from.length; i < l; i++) {
      text = text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    text = text
      .replace(/[^a-z0-9 _-]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return text
  }

  static hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : false
  }

  static isLightColor(hex: string) {
    const color = this.hexToRgb(hex)
    if (!color) {
      return false
    }

    const contrast = 1 - (0.299 * color.r + 0.587 * color.g + 0.114 * color.b) / 255
    return contrast < 0.5
  }

  static flatObject(object, path = [], flatted = {}) {
    Object.keys(object).forEach(key => {
      if (object[key]) {
        // Set reference
        path.push(key)

        if (object[key] instanceof Date) {
          flatted[path.join('.')] = object[key].toISOString()
        } else if (typeof object[key] === 'object') {
          this.flatObject(object[key], path, flatted)
        } else {
          flatted[path.join('.')] = object[key]
        }

        path.pop()
      }
    })
    return flatted
  }

  static setZIndex() {
    let zIndex = 1040
    const elements = Array.from(document.getElementsByClassName('modal'))

    elements.reverse().forEach(element => {
      element['style']['zIndex'] = zIndex
      zIndex -= 10
    })
  }
}
