import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

// Routing
import { AppRoutingModule } from './app.routing'

// Components
import { AppComponent } from './app.component'
import { LoginComponent } from './features/login/login.component'

// Modules
import { CoreModule } from './core/core.module'
import { SDKSaraCleanModule } from '../_sdk/sdk.module'

// External modules
import { ToastrModule } from 'ngx-toastr'
import { ModalModule } from 'ngx-bootstrap/modal'
import { NgxSpinnerModule } from 'ngx-spinner'
import { NgxStripeModule } from 'ngx-stripe'
import { environment } from '../environments/environment'

// Devextreme configuration and localization

import config from 'devextreme/core/config'
import { locale, loadMessages } from 'devextreme/localization'

const messagesIt = require('devextreme/localization/messages/it.json')

// Fixes missing translations
messagesIt.it['dxHtmlEditor-dialogBackgroundCaption'] = 'Colore sfondo'
messagesIt.it['dxHtmlEditor-dialogColorCaption'] = 'Colore testo'
messagesIt.it['dxHtmlEditor-dialogLinkCaption'] = 'Aggiungi collegamento'
messagesIt.it['dxHtmlEditor-dialogLinkTargetField'] = 'Apri in una nuova finestra'
messagesIt.it['dxHtmlEditor-dialogLinkTextField'] = 'Testo'
messagesIt.it['dxHtmlEditor-dialogLinkUrlField'] = 'URL'
messagesIt.it['dxHtmlEditor-heading'] = 'Testata'
messagesIt.it['dxHtmlEditor-normalText'] = 'Paragrafo'

loadMessages(messagesIt)
locale(navigator.language)
config({
  forceIsoDateParsing: true,
})

@NgModule({
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Routing modules
    AppRoutingModule,

    // Modules
    CoreModule.forRoot(),
    SDKSaraCleanModule.forRoot(),

    // External
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    NgxSpinnerModule,
  ],
  exports: [ToastrModule],
  declarations: [AppComponent, LoginComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
