export enum EFacilityType {
  laundry = 'LAUNDRY',
  cleaning = 'CLEANING',
  maintenance = 'MAINTENANCE',
  carwash = 'CARWASH',
}

export enum EFacilityPromotionType {
  percentage = 'PERCENTAGE',
  fixed = 'FIXED',
}

export const FacilityTypes = [
  {
    code: EFacilityType.laundry,
    name: 'Lavanderia',
  },
  {
    code: EFacilityType.cleaning,
    name: 'Pulizia',
  },
  {
    code: EFacilityType.carwash,
    name: 'Lavaggio auto',
  },
  {
    code: EFacilityType.maintenance,
    name: 'Altri servizi',
  },
]

export const FacilityPromotionTypes = [
  {
    code: EFacilityPromotionType.fixed,
    name: 'Prezzo fisso',
  },
  {
    code: EFacilityPromotionType.percentage,
    name: 'Percentuale',
  },
]
