export enum EWeekDays {
  mon = 'MON',
  tue = 'TUE',
  wed = 'WED',
  thu = 'THU',
  fri = 'FRI',
  sat = 'SAT',
  sun = 'SUN',
}

export const WeekDays = [
  {
    code: EWeekDays.mon,
    value: 'Lunedì',
  },
  {
    code: EWeekDays.tue,
    value: 'Martedì',
  },
  {
    code: EWeekDays.wed,
    value: 'Mercoledì',
  },
  {
    code: EWeekDays.thu,
    value: 'Giovedì',
  },
  {
    code: EWeekDays.fri,
    value: 'Venerdì',
  },
  {
    code: EWeekDays.sat,
    value: 'Sabato',
  },
  {
    code: EWeekDays.sun,
    value: 'Domenica',
  },
]

export const Hours = [
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
]
