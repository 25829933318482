<!-- Page -->
<div class="page login-page d-flex justify-content-center">
  <div class="login-wrapper align-self-center p-5">
    <img class="logo mb-5" src="assets/img/logo.png" />
    <form class="form" [formGroup]="loginForm" (ngSubmit)="authenticate()">
      <div class="form-group">
        <input
          type="text"
          class="form-control input-sm"
          placeholder="username"
          formControlName="username"
          required
          lowercase
        />
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control input-sm"
          placeholder="password"
          formControlName="password"
          required
        />
      </div>
      <div class="custom-control custom-checkbox">
        <input
          type="checkbox"
          class="custom-control-input"
          id="remember"
          formControlName="remember"
        />
        <label class="custom-control-label" for="remember">Remember me</label>
      </div>
      <button
        class="btn btn-primary btn-block mt-4 mb-2"
        type="submit"
        [disabled]="!loginForm.valid"
      >
        <i class="fas fa-sign-in-alt mr-2"></i> <ng-container>Login</ng-container>
      </button>
    </form>
    <div class="mt-4 text-center">
      <span>v{{ version }}</span>
    </div>
  </div>
</div>

<!-- Modal alert -->
<div
  bsModal
  #alertModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <p>Ops, ci risulta una sessione già attiva.</p>
        <button class="btn btn-default" type="button" (click)="alertModal.hide()">Annulla</button>
        <button class="btn btn-primary ml-1" type="button" (click)="authenticate(true)">
          Accedi comunque
        </button>
      </div>
    </div>
  </div>
</div>
