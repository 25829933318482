import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AuthService, BaseService } from '../core/'

const MODEL = 'municipalities'

@Injectable()
export class MunicipalityService extends BaseService {
  protected model = MODEL

  constructor(http: HttpClient, auth: AuthService) {
    super(http, auth)
  }
}
