import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'

import { Utils } from 'app/utils'

import { AuthService } from '_sdk'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toast: ToastrService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          // Unathorized
          if (err.status === 401) {
            if (!!this.authService.getCurrentUserId()) {
              this.authService.clear()
            }

            this.router.navigate(['/login'])
            this.toast.error('Ops, sessione scaduta', 'Time out')
          } else {
            Utils.parseError(this.toast, err)
          }
        }

        return throwError(err)
      }),
    )
  }
}
