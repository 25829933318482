/**
 * Storage class
 */
export class Storage {
  get(key: string): any {}
  set(key: string, value: any): any {}
  remove(key: string): any {}
}

/**
 * InternalStorage class used for internal use only
 */

export class InternalStorage extends Storage {}

/**
 * SDKStorage class used for custom use
 */
export class SDKStorage extends Storage {}

/**
 * CookieStorage class
 */
export class CookieStorage extends Storage {}
