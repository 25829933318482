import * as _ from 'lodash'
import { IPriceList } from '../models'

// Initial states

const PriceListInitialState: IPriceList = {
  code: undefined,
  name: undefined,
  isActive: true,
}

// PriceList reducers

export function initPriceList(priceList = {}): IPriceList {
  return _.defaultsDeep(priceList, PriceListInitialState)
}
