export enum EOrderStatus {
  pending = 'PENDING',
  confirmed = 'CONFIRMED',
  finished = 'FINISHED',
  canceled = 'CANCELED',
}

export const OrderStatus = [
  {
    code: 'PENDING',
    name: 'In attesa',
    primary: true,
    tooltip: 'Ordine in attesa di essere confermato',
  },
  {
    code: 'CONFIRMED',
    name: 'Confermato',
    primary: true,
    tooltip: 'Ordine confermato e in attesa di essere preso in carico',
  },
  {
    code: 'FINISHED',
    name: 'Terminato',
    primary: true,
    tooltip: 'Ordine terminato, tutti gli appuntamenti sono stati portati a termine',
  },
  {
    code: 'CANCELED',
    name: 'Annullato',
    primary: false,
    tooltip: 'Ordine annullato',
  },
]

export const OrderActions = {
  PENDING: {
    permissions: ['editFacilities', 'editHeader', 'editNotes', 'saveOrder', 'deleteOrder'],
    actions: [
      {
        label: 'Salva e conferma ordine',
        action: 'confirm',
      },
    ],
  },
  CONFIRMED: {
    permissions: ['editHeader', 'editNotes', 'viewCalendar', 'saveOrder', 'cancelOrder'],
    actions: [
      {
        label: 'Salva e segna come terminato',
        action: 'finish',
      },
    ],
  },
  FINISHED: {
    permissions: ['editHeader', 'editNotes', 'viewCalendar', 'saveOrder'],
    actions: [],
  },
  CANCELED: {
    permissions: ['editNotes', 'saveOrder'],
    actions: [],
  },
}
