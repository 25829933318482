import * as _ from 'lodash'
import { IPayment } from '../models'
import { EPaymentKind } from '../consts'

// Initial states

const PaymentInitialState: IPayment = {
  name: {},
  description: {},
  kind: undefined,
  isActive: true,
  isDefault: false,
}

// Payment reducers

export function initPayment(payment = {}): IPayment {
  return _.defaultsDeep(payment, PaymentInitialState)
}

export function setPaymentSettings(payment: IPayment): IPayment {
  if (payment.kind === EPaymentKind.paypal) {
    return {
      ...payment,
      paypal: {
        email: undefined,
        clientId: undefined,
        secret: undefined,
      },
    }
  } else if (payment.kind === EPaymentKind.stripe) {
    return {
      ...payment,
      stripe: {
        sKey: undefined,
        pKey: undefined,
      },
    }
  } else {
    return { ...payment, paypal: undefined }
  }
}
