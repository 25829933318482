/**
 * @module SDKSaraCleanModule
 * @author Nicolò Manni <nicolo.manni@crealab.dev>
 */
import { NgModule, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'

// Directives
import {
  EqualValidator,
  MaxValidator,
  MinValidator,
  SlugifyDirective,
  UniqueValidator,
} from './directives/'

// Guards
import { AuthGuard, UnAuthGuard } from './guards/'

// Pipes
import {
  AddressPipe,
  FilterPipe,
  FindPipe,
  IncludesPipe,
  JoinPipe,
  MapPipe,
  SumPipe,
  TimePipe,
} from './pipes/'

// Services
import { AuthService } from './services/core'
import {
  CountryService,
  CurrencyService,
  CustomerService,
  FacilityService,
  JobService,
  MunicipalityService,
  OrderService,
  PaymentService,
  PriceListService,
  SettingsService,
  UserService,
} from './services/custom'

// Storage
import { InternalStorage, SDKStorage, CookieStorage } from './storage/storage.swaps'
import { StorageBrowser } from './storage/storage.browser'
import { CookieBrowser } from './storage/cookie.browser'

// SDK configuration
export const SDKConfiguration: any = {
  prefix: '$SaraCleanSDK$',
}

@NgModule({
  imports: [CommonModule],
  declarations: [
    EqualValidator,
    MaxValidator,
    MinValidator,
    SlugifyDirective,
    UniqueValidator,
    AddressPipe,
    FilterPipe,
    FindPipe,
    IncludesPipe,
    JoinPipe,
    MapPipe,
    SumPipe,
    TimePipe,
  ],
  exports: [
    EqualValidator,
    MaxValidator,
    MinValidator,
    SlugifyDirective,
    UniqueValidator,
    AddressPipe,
    FilterPipe,
    FindPipe,
    IncludesPipe,
    JoinPipe,
    MapPipe,
    SumPipe,
    TimePipe,
  ],
})
export class SDKSaraCleanModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SDKSaraCleanModule,
      providers: [
        // Common
        AuthService,
        AuthGuard,
        UnAuthGuard,

        // Custom
        CountryService,
        CurrencyService,
        CustomerService,
        FacilityService,
        JobService,
        MunicipalityService,
        OrderService,
        PaymentService,
        PriceListService,
        SettingsService,
        UserService,

        // Configuration
        { provide: 'SDKConfiguration', useValue: SDKConfiguration },

        // Storage
        { provide: InternalStorage, useClass: StorageBrowser },
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: CookieStorage, useClass: CookieBrowser },
      ],
    }
  }
}
