import * as _ from 'lodash'
import { IToken } from '../models'

// Initial states

const TokenInitialState: IToken = {}

// Token reducers

export function initToken(token = {}): IToken {
  return _.defaultsDeep(token, TokenInitialState)
}
