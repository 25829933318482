import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

// Components
import {
  BreadcrumbsComponent,
  MenuPrimaryComponent,
  MenuSecondaryComponent,
  ModalAddressComponent,
  ModalAlertComponent,
  ModalClosureComponent,
  ModalDeleteComponent,
  ModalFrameComponent,
  ModalPaymentStripeComponent,
  PageFrameComponent,
  RouteStatusComponent,
  SidebarComponent,
  AddressComponent,
} from './components'

// Interceptors
import { JwtInterceptor, LoadingInterceptor, TokenInterceptor } from './interceptors/'

// External modules
import { NgxSpinnerModule } from 'ngx-spinner'
import { DxSelectBoxModule } from 'devextreme-angular'
import { NgxStripeModule } from 'ngx-stripe'

// SDK Library
import { SDKSaraCleanModule } from '_sdk/sdk.module'

@NgModule({
  declarations: [
    AddressComponent,
    BreadcrumbsComponent,
    MenuPrimaryComponent,
    MenuSecondaryComponent,
    ModalAddressComponent,
    ModalAlertComponent,
    ModalClosureComponent,
    ModalDeleteComponent,
    ModalFrameComponent,
    ModalPaymentStripeComponent,
    PageFrameComponent,
    RouteStatusComponent,
    SidebarComponent,
  ],
  imports: [
    // Angular modules
    CommonModule,
    RouterModule,
    FormsModule,

    // External
    NgxSpinnerModule,
    DxSelectBoxModule,
    NgxStripeModule,

    // Internal
    SDKSaraCleanModule,
  ],
  exports: [
    AddressComponent,
    BreadcrumbsComponent,
    MenuPrimaryComponent,
    MenuSecondaryComponent,
    ModalAddressComponent,
    ModalAlertComponent,
    ModalClosureComponent,
    ModalDeleteComponent,
    ModalFrameComponent,
    ModalPaymentStripeComponent,
    PageFrameComponent,
    RouteStatusComponent,
    SidebarComponent,
    SDKSaraCleanModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        // Interceptors
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      ],
    }
  }
}
