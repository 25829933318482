import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'

// Components
import { ModalDirective } from 'ngx-bootstrap/modal'

// Services
import { UserService } from '../../../_sdk'

// Environments
import { environment } from '../../../environments/environment'
const { version: appVersion } = require('../../../../package.json')

@Component({
  selector: 'sc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('alertModal', { static: true }) alertModal: ModalDirective

  public loginForm: FormGroup
  public production = environment.production
  public localhost = environment.localhost
  public version = appVersion

  // Subscriptions
  private subscriptions$ = new Subscription()

  constructor(
    private router: Router,
    private userService: UserService,
    private builder: FormBuilder,
  ) {}

  // Lifecycle functions

  ngOnInit() {
    this.loginForm = this.builder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: [true],
    })
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe()
  }

  // Auth functions

  authenticate(forceLogin = false) {
    const auth = {
      username: this.loginForm.value.username.toLowerCase(),
      password: this.loginForm.value.password,
      force: forceLogin,
    }

    this.subscriptions$.add(
      this.userService.login(auth, this.loginForm.value.remember).subscribe(
        res => this.router.navigate(['/']),
        err => this.parseError(err),
      ),
    )
  }

  parseError(httpError) {
    if (httpError.status === 409) {
      this.alertModal.show()
    }
  }
}
