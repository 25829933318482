import { Injectable, Inject } from '@angular/core'

import { InternalStorage, CookieStorage } from '../../storage/storage.swaps'
import { IToken } from '../../models'
import { initToken } from '../../reducers'

@Injectable()
export class AuthService {
  private token: IToken = initToken()
  protected prefix = ''

  constructor(
    private storage: InternalStorage,
    private cookie: CookieStorage,
    @Inject('SDKConfiguration') protected config: any,
  ) {
    this.prefix = this.config.prefix
    this.token._id = this.load('_id')
    this.token.name = this.load('name')
    this.token.surname = this.load('surname')
    this.token.username = this.load('username')
    this.token.role = this.load('role')
    this.token.rememberMe = this.load('rememberMe')
    this.token.token = this.cookie.get('Authorization')
  }

  public setRememberMe(value: boolean): void {
    this.token.rememberMe = value
  }

  public getToken(): IToken {
    return this.token
  }

  public getAccessTokenKey(): any {
    return this.token.token
  }

  public getCurrentUserId(): any {
    return this.token._id
  }

  public getCurrentUserRole(): any {
    return this.token.role
  }

  public getCurrentUserData(): any {
    return this.token
  }

  public updateCurrentUserData(prop: string, value: any): void {
    this.token[prop] = value

    if (this.token.rememberMe) {
      this.persist(prop, value)
    }
  }

  public save(): void {
    if (this.token.rememberMe) {
      this.persist('_id', this.token._id)
      this.persist('name', this.token.name)
      this.persist('surname', this.token.surname)
      this.persist('username', this.token.username)
      this.persist('role', this.token.role)
      this.persist('rememberMe', this.token.rememberMe)
      this.cookie.set('Authorization', this.token.token)
    }
  }

  public setUser(token: IToken) {
    this.token = { ...this.token, ...token }
  }

  protected load(prop: string): any {
    return this.storage.get(`${this.prefix}${prop}`)
  }

  public clear(): void {
    this.storage.remove(`${this.prefix}_id`)
    this.storage.remove(`${this.prefix}name`)
    this.storage.remove(`${this.prefix}surname`)
    this.storage.remove(`${this.prefix}username`)
    this.storage.remove(`${this.prefix}role`)
    this.storage.remove(`${this.prefix}rememberMe`)
    this.token = initToken()
    this.cookie.remove('Authorization')
  }

  protected persist(prop: string, value: any): void {
    try {
      this.storage.set(
        `${this.prefix}${prop}`,
        typeof value === 'object' ? JSON.stringify(value) : value,
      )
    } catch (err) {
      console.error('Cannot access local/session storage:', err)
    }
  }
}
