import * as _ from 'lodash'

import {
  IFacility,
  IFacilityPrice,
  IFacilityReservationSettings,
  IFacilityPromotion,
  IFacilityPriceList,
} from '../models'
import { EFacilityType, EFacilityPromotionType, EWeekDays } from '../consts'
import moment from 'moment'

// Initial states

const FacilityPromotionInitialState: IFacilityPromotion = {
  name: undefined,
  type: EFacilityPromotionType.fixed,
  isActive: true,
}

const FacilitySettingsInitialState: IFacilityReservationSettings = {
  minHoursInAdvance: undefined,
  bookableDays: {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: [],
  },
  municipalities: [],
}

const FacilityPriceInitialState: IFacilityPrice = {
  listing: 0,
  net: 0,
  taxRate: 0,
  isUntaxable: false,
}

const FacilityPriceListInitialState: IFacilityPriceList = {
  code: undefined,
  listing: 0,
  net: 0,
  bookableDays: {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: [],
  },
}

const FacilityInitialState: IFacility = {
  SKU: undefined,
  name: {},
  type: EFacilityType.cleaning,
  description: {},
  price: FacilityPriceInitialState,
  techInfo: {},
  settings: FacilitySettingsInitialState,
  promotions: [],
}

// Reducers

export function initFacility(facility = {}): IFacility {
  return _.defaultsDeep(facility, FacilityInitialState)
}

export function initFacilityPromotion(
  promotion: Partial<IFacilityPromotion> = {},
): IFacilityPromotion {
  return _.defaultsDeep(promotion, FacilityPromotionInitialState)
}

export function initFacilityPriceList(
  priceList: Partial<IFacilityPriceList> = {},
): IFacilityPriceList {
  return _.defaultsDeep(priceList, FacilityPriceListInitialState)
}

// Prices

export function FacilityUpdatePrice(facility: IFacility): IFacility {
  return {
    ...facility,
    price: {
      ...facility.price,
      listing: +(+facility.price.net / ((100 - +facility.price.taxRate) / 100)).toFixed(2),
    },
  }
}

export function FacilityGetPrices(facility: IFacility, orderedQty = 1) {
  let price = facility.price.net
  let priceWithTaxes = facility.price.listing

  // Check promotions
  const promotions = FacilityGetActivePromotions(facility, orderedQty)
  if (promotions.length) {
    priceWithTaxes = promotions.reduce((acc, promo) => FacilityEstimatePromotion(promo, acc), price)
    price = (priceWithTaxes * (100 - facility.price.taxRate)) / 100
  }

  return {
    unitPrice: price,
    unitPriceWithTaxes: priceWithTaxes,
    totalTaxes: priceWithTaxes - price,
  }
}

// Promotions

export function FacilityEstimatePromotion(promotion: IFacilityPromotion, price: number): number {
  return promotion.type === EFacilityPromotionType.fixed
    ? promotion.amount
    : price - price * (promotion.percentage / 100)
}

export function FacilityGetActivePromotions(
  facility: IFacility,
  orderedQty = 1,
): IFacilityPromotion[] {
  return facility.promotions.filter(promotion => {
    if (!promotion.isActive) {
      return false
    }

    // Check dates
    if (!!promotion.startDate || !!promotion.endDate) {
      const isBetween =
        promotion.startDate &&
        promotion.endDate &&
        moment().isBetween(promotion.startDate, promotion.endDate, 'day', '[]')
      const greaterThan =
        promotion.startDate && !promotion.endDate && moment() >= moment(promotion.startDate)
      const lesserThan =
        !promotion.startDate && promotion.endDate && moment() <= moment(promotion.endDate)

      if (!isBetween && !greaterThan && !lesserThan) {
        return false
      }
    }

    // Check quantities
    if (
      (!!promotion.maxQty && orderedQty > promotion.maxQty) ||
      (!!promotion.minQty && orderedQty < promotion.minQty)
    ) {
      return false
    }

    return true
  })
}
