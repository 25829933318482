import * as _ from 'lodash'
import { ICustomer, IAddress, IOrder } from '../models'
import { initAddress } from './address.reducer'

// Initial states

const CustomerInitialState: ICustomer = {
  name: undefined,
  billingAddress: initAddress(),
  addresses: [],
  settings: {},
}

// Customer reducers

export function initCustomer(customer = {}): ICustomer {
  return _.defaultsDeep(customer, CustomerInitialState)
}

export function enableCustomerAuth(customer: ICustomer): ICustomer {
  return {
    ...customer,
    auth: {
      email: undefined,
      password: undefined,
      isActive: true,
    },
  }
}

export function CustomerGetDefaultAddress(customer: ICustomer): IAddress {
  return customer.addresses && customer.addresses.length
    ? customer.addresses.find(addr => addr.isDefault) || customer.addresses[0]
    : customer.billingAddress
}

export function CustomerByOrder(order: IOrder): ICustomer {
  return initCustomer({
    name: order.header.address.name,
    billingAddress: initAddress(order.header.billingAddress),
    addresses: [
      {
        ...order.header.address,
        isDefault: true,
      },
    ],
  })
}

export function CustomerSetAddress(customer: ICustomer): ICustomer {
  return {
    ...customer,
    addresses: [
      {
        ...customer.billingAddress,
        name: customer.name,
        isDefault: true,
      },
    ],
  }
}
