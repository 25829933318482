import * as _ from 'lodash'
import { IUser } from '../models'

// Initial states

const UserInitialState: IUser = {
  name: undefined,
  surname: undefined,
  username: undefined,
  password: undefined,
  isActive: true,
}

// User reducers

export function initUser(user = {}): IUser {
  return _.defaultsDeep(user, UserInitialState)
}
