import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AuthService, BaseService } from '../core/'
import { ICustomer, IMunicipality, IPriceList } from '_sdk/models'
import { Observable, of } from 'rxjs'
import { finalize, switchMap, tap } from 'rxjs/operators'
import { InternalStorage } from '_sdk/storage/storage.swaps'
import { PriceListService } from './price-list.service'
import { MunicipalityService } from './municipality.service'

const MODEL = 'customers'

@Injectable()
export class CustomerService extends BaseService {
  protected model = MODEL

  public customer?: ICustomer
  public priceList?: IPriceList
  public municipality?: IMunicipality

  public customerId?: string
  public municipalityId?: string
  public priceListCode?: string

  constructor(
    http: HttpClient,
    auth: AuthService,
    private storage: InternalStorage,
    private priceListService: PriceListService,
    private municipalityService: MunicipalityService,
  ) {
    super(http, auth)
    this.load()
  }

  // Lifecycle

  initialize(): Observable<ICustomer | void> {
    if (!this.customerId) {
      return of(undefined)
    }

    return this.isLogged(this.customerId).pipe(
      switchMap(res => (res.isLogged ? this.loadCustomerData() : of(undefined))),
    )
  }

  updateSettings(municipalityId?: string, priceListCode?: string): Observable<void> {
    this.municipalityId = municipalityId
    this.priceListCode = priceListCode
    this.save()

    if (!this.customer) {
      return of()
    }

    this.customer.settings = {
      ...(this.customer.settings || {}),
      municipalityId: this.municipalityId,
      priceListCode: this.priceListCode,
    }

    return this.update(this.customerId, this.customer).pipe(
      switchMap(() => this.loadMunicipality()),
      switchMap(() => this.loadPriceList()),
    )
  }

  // Routes

  login(
    credentials: {
      email: string
      password: string
    },
    rememberMe: boolean = true,
  ): Observable<{ customer: ICustomer; token: string }> {
    return this.http
      .post<{ customer: ICustomer; token: string }>(
        `${this.path}/${this.version}/${this.model}/authenticate`,
        credentials,
      )
      .pipe(
        tap(res => this.setCustomer(res.customer, rememberMe)),
        switchMap(() => this.loadMunicipality()),
        switchMap(() => this.loadPriceList()),
      )
  }

  logout(customerId: string): Observable<any> {
    return this.http
      .post(`${this.path}/${this.version}/${this.model}/${customerId}/logout`, {})
      .pipe(finalize(() => this.unsetCustomer()))
  }

  register(customer: ICustomer): Observable<{ customer: ICustomer; token: string }> {
    return this.http
      .post(`${this.path}/${this.version}/${this.model}/register`, customer)
      .pipe(
        switchMap(_customer =>
          this.login({ email: customer.auth.email, password: customer.auth.password }),
        ),
      )
  }

  isLogged(customerId: string): Observable<{ isLogged: boolean }> {
    return this.http.post<{ isLogged: boolean }>(
      `${this.path}/${this.version}/${this.model}/${customerId}/isLogged`,
      {},
    )
  }

  changePassword(customerId: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.http.post(
      `${this.path}/${this.version}/${this.model}/${customerId}/changePassword`,
      { oldPassword, newPassword },
    )
  }

  setPassword(customerId: string, email: string, password: string): Observable<any> {
    return this.http.post(`${this.path}/${this.version}/${this.model}/${customerId}/setPassword`, {
      email,
      password,
    })
  }

  // Auth Utilities

  private loadCustomerData() {
    return this.findByKey<ICustomer>(this.customerId).pipe(
      tap(customer => this.setCustomer(customer)),
      switchMap(() => this.loadMunicipality()),
      switchMap(() => this.loadPriceList()),
    )
  }

  private loadMunicipality() {
    return this.municipalityId
      ? this.municipalityService
          .findByKey(this.municipalityId)
          .pipe(tap(municipality => (this.municipality = municipality)))
      : of(undefined)
  }

  private loadPriceList() {
    return this.priceListCode
      ? this.priceListService
          .findAll({ code: this.priceListCode, isActive: true })
          .pipe(tap(priceLists => (this.priceList = priceLists.length ? priceLists[0] : undefined)))
      : of(undefined)
  }

  private setCustomer(customer: ICustomer, rememberMe?: boolean) {
    this.customer = customer
    this.customerId = customer._id
    this.municipalityId = customer.settings?.municipalityId
    this.priceListCode = customer.settings?.priceListCode

    if (rememberMe) {
      this.save()
    }
  }

  private unsetCustomer() {
    this.customer = undefined
    this.municipality = undefined
    this.priceList = undefined

    this.customerId = undefined
    this.municipalityId = undefined
    this.priceListCode = undefined

    this.storage.remove('cid')
    this.storage.remove('mid')
    this.storage.remove('plc')
  }

  private load() {
    this.customerId = this.storage.get('cid')
    this.municipalityId = this.storage.get('mid')
    this.priceListCode = this.storage.get('plc')
  }

  private save() {
    this.customerId && this.storage.set('cid', this.customerId)
    this.municipalityId && this.storage.set('mid', this.municipalityId)
    this.priceListCode && this.storage.set('plc', this.priceListCode)
  }
}
