import * as _ from 'lodash'
import { ICountry } from '../models'

// Initial states

const CountryInitialState: ICountry = {
  alpha2Code: undefined,
  alpha3Code: undefined,
  name: undefined,
}

// Country reducers

export function initCountry(country = {}): ICountry {
  return _.defaultsDeep(country, CountryInitialState)
}
