import * as _ from 'lodash'
import { IAddress } from '../models'

// Initial states

const AddressInitialState: IAddress = {}

// Address reducers

export function initAddress(address = {}): IAddress {
  return _.defaultsDeep(address, AddressInitialState)
}
