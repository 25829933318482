import { Injectable } from '@angular/core'
import { Storage } from './storage.swaps'

@Injectable()
export class StorageBrowser extends Storage {
  set(key: string, value: any) {
    value = typeof value === 'object' ? JSON.stringify(value) : value
    localStorage.setItem(key, value)
  }

  get(key: string): any {
    const data: string = localStorage.getItem(key) as string
    return this.isJSON(data) ? JSON.parse(data) : data
  }

  remove(key: string): any {
    if (localStorage[key]) {
      localStorage.removeItem(key)
    } else {
      console.log('Trying to remove unexisting key: ', key)
    }
  }

  private isJSON(data: string) {
    try {
      JSON.parse(data)
    } catch (error) {
      return false
    }
    return true
  }
}
