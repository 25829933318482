import * as _ from 'lodash'
import { ICurrency } from '../models'

// Initial states

const CurrencyInitialState: ICurrency = {
  code: undefined,
  symbol: undefined,
}

// Currency reducers

export function initCurrency(currency = {}): ICurrency {
  return _.defaultsDeep(currency, CurrencyInitialState)
}
