import * as _ from 'lodash'
import { ISettings } from '../models'

// Initial states

const SettingsInitialState: ISettings = {
  code: undefined,
  calendar: {
    enabled: false,
  },
  email: {
    enabled: false,
  },
}

// Country reducers

export function initSettings(settings = {}): ISettings {
  return _.defaultsDeep(settings, SettingsInitialState)
}
