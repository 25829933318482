import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map, finalize } from 'rxjs/operators'

import { AuthService, BaseService } from '../core/'
import { IToken } from '../../models'

const MODEL = 'users'

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  protected model = MODEL

  constructor(http: HttpClient, auth: AuthService) {
    super(http, auth)
  }

  login(credentials: any, rememberMe: boolean = true): Observable<boolean> {
    return this.http.post(`${this.path}/authenticate`, credentials).pipe(
      map((res: any) => {
        const token = res
        let logged = false

        // Check jwt token response
        if (token) {
          token.rememberMe = rememberMe

          // Save user data
          this.auth.setUser(token)
          this.auth.save()

          logged = true
        }

        return logged
      }),
    )
  }

  logout(): Observable<any> {
    return this.http.post(`${this.path}/logout`, {}).pipe(finalize(() => this.auth.clear()))
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.http
      .post(`${this.path}/changePassword`, { oldPassword, newPassword })
      .pipe(map(() => this.auth.clear()))
  }

  setPassword(username: string, password: string): Observable<any> {
    return this.http.post(`${this.path}/setPassword`, { username, password })
  }

  getCurrent(): Observable<any> {
    return this.http.get(`${this.path}/${this.version}/${this.model}/me`)
  }

  getCurrentWarehouse(): Observable<any> {
    return this.http.get(`${this.path}/${this.version}/${this.model}/me/warehouse`)
  }

  getCurrentTenant(): Observable<any> {
    return this.http.get(`${this.path}/${this.version}/${this.model}/me/tenant`)
  }

  getCachedCurrent() {
    return this.auth.getCurrentUserData()
  }

  getCachedToken(): IToken {
    return this.auth.getToken()
  }

  getCachedRole() {
    return this.auth.getCurrentUserRole()
  }

  getCachedId() {
    return this.auth.getCurrentUserId()
  }

  updateCurrent(prop: string, value: any) {
    this.auth.updateCurrentUserData(prop, value)
  }

  isAuthenticated() {
    return !(!this.getCachedId() || this.getCachedId() === '' || this.getCachedId() === 'null')
  }
}
