import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Guard
import { AuthGuard, UnAuthGuard } from '../_sdk'

// Components
import { LoginComponent } from './features/login/login.component'

const routes: Routes = [
  // Common pages
  { path: 'login', component: LoginComponent, canActivate: [UnAuthGuard] },

  // Dashboard
  {
    path: '',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(mod => mod.DashboardModule),
    canActivate: [AuthGuard],
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'login' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
