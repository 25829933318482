import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthService, BaseService } from '../core/'

const MODEL = 'facilities'

@Injectable()
export class FacilityService extends BaseService {
  protected model = MODEL

  constructor(http: HttpClient, auth: AuthService) {
    super(http, auth)
  }

  isUnique(field: any): Observable<any> {
    return this.http.post(`${this.path}/${this.version}/${this.model}/isUnique`, field)
  }
}
