import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AuthService, BaseService } from '../core/'
import { Observable } from 'rxjs'

const MODEL = 'orders'

@Injectable()
export class OrderService extends BaseService {
  protected model = MODEL

  constructor(http: HttpClient, auth: AuthService) {
    super(http, auth)
  }

  process(id: string, action: 'confirm' | 'pending' | 'finish', params: any = {}): Observable<any> {
    return this.http.post(`${this.path}/${this.version}/${this.model}/${id}/${action}`, params)
  }

  cancel(id: string): Observable<any> {
    return this.http.post(`${this.path}/${this.version}/${this.model}/${id}/cancel`, {})
  }

  dashboard(startDate?: string, endDate?: string): Observable<any> {
    let query = ''

    if (startDate && endDate) {
      query += '?header.date>=' + startDate
      query += '&header.date<' + endDate
    }

    return this.http.get(`${this.path}/${this.version}/${this.model}/dashboard${query}`)
  }
}
