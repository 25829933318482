export enum EPaymentKind {
  generic = 'GENERIC',
  paypal = 'PAYPAL',
  stripe = 'STRIPE',
}

export const PaymentKindAvailable = [
  {
    code: EPaymentKind.generic,
    name: 'Generico',
    icon: 'fad fa-cash-register',
  },
  {
    code: EPaymentKind.stripe,
    name: 'Stripe',
    icon: 'fab fa-stripe-s',
  },
]
