import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { AuthService } from './auth.service'
import { environment } from '../../../environments/environment'

export abstract class BaseService {
  protected path: string
  protected version: string
  protected model: string
  private limit = 0

  constructor(protected http: HttpClient, protected auth: AuthService) {
    this.path = environment.apiUrl
    this.version = environment.apiVersion
  }

  // CRUD functions

  findAll<Type = any>(params?: any): Observable<Type> {
    let query = ''
    const searchParams = []

    if (params) {
      if (typeof params === 'object') {
        // Check limit size
        if (!params['limit'] && params['_id']) {
          params['limit'] = params['_id'].length
        }

        for (const key in params) {
          if (key === 'filters') {
            searchParams.push(params[key])
          } else {
            searchParams.push(key + '=' + params[key])
          }
        }

        query = '?' + searchParams.join('&')
      } else {
        query = '?' + params
      }
    } else {
      query = '?limit=' + this.limit
    }

    return this.http
      .get<Type>(`${this.path}/${this.version}/${this.model}${query}`)
      .pipe(
        map(res => (!res['data'] ? res : params && params.client === 'table' ? res : res['data'])),
      )
  }

  findByKey<Type = any>(key: string, params?: any): Observable<Type> {
    let searchParams = new HttpParams()

    if (params) {
      for (const _key of Object.keys(params)) {
        searchParams = searchParams.append(_key, params[_key])
      }
    }

    return this.http.get<Type>(`${this.path}/${this.version}/${this.model}/${key}`, {
      params: searchParams,
    })
  }

  insert<Type = any>(item: Type): Observable<any> {
    return this.http.post(`${this.path}/${this.version}/${this.model}`, item)
  }

  update<Type = any>(id: string, update: Partial<Type>): Observable<any> {
    return this.http.put(`${this.path}/${this.version}/${this.model}/${id}`, update)
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.path}/${this.version}/${this.model}/${id}`)
  }

  // Children models: CRUD functions

  findAllChildren(key: string, childModel: string, params?: any): Observable<any> {
    let query = ''
    const searchParams = []

    if (params) {
      if (typeof params === 'object') {
        for (const k in params) {
          if (k === 'filters') {
            searchParams.push(params[k])
          } else {
            searchParams.push(k + '=' + params[k])
          }
        }

        query = '?' + searchParams.join('&')
      } else {
        query = '?' + params
      }
    } else {
      query = '?limit=' + this.limit
    }

    return this.http
      .get(`${this.path}/${this.version}/${this.model}/${key}/${childModel}${query}`)
      .pipe(
        map(res => (!res['data'] ? res : params && params.client === 'table' ? res : res['data'])),
      )
  }

  findChildByKey(key: string, childModel: string, childKey: string, params?: any): Observable<any> {
    let searchParams = new HttpParams()

    if (params) {
      for (const _key of Object.keys(params)) {
        searchParams = searchParams.append(_key, params[_key])
      }
    }

    return this.http.get(
      `${this.path}/${this.version}/${this.model}/${key}/${childModel}/${childKey}`,
      { params: searchParams },
    )
  }

  insertChild(key: string, childModel: string, item: any): Observable<any> {
    return this.http.post(`${this.path}/${this.version}/${this.model}/${key}/${childModel}`, item)
  }

  insertChildren(key: string, childModel: string, items: any[]): Observable<any> {
    return this.http.post(`${this.path}/${this.version}/${this.model}/${key}/${childModel}`, {
      [childModel]: items,
    })
  }

  updateChild(key: string, childModel: string, id: string, update: any): Observable<any> {
    return this.http.put(
      `${this.path}/${this.version}/${this.model}/${key}/${childModel}/${id}`,
      update,
    )
  }

  deleteChild(key: string, childModel: string, id: string): Observable<any> {
    return this.http.delete(`${this.path}/${this.version}/${this.model}/${key}/${childModel}/${id}`)
  }
}
