import * as _ from 'lodash'

import { IOrder, IOrderHeader, IOrderRow, ICustomer, IFacility, IOrderPayment } from '../models'
import { EOrderStatus } from '../consts'
import { CustomerGetDefaultAddress } from './customer.reducer'
import { FacilityGetPrices } from './facility.reducer'

// Initial states

const headerInitialState: IOrderHeader = {
  date: new Date(),
  payments: [],
  paymentId: undefined,
  currency: 'EUR',
  subTotal: 0,
  subTotalWithTaxes: 0,
  bodyTaxes: 0,
  totalDiscount: 0,
  totalDiscountWithTaxes: 0,
  totalAmount: 0,
  address: {},
  billingAddress: {},
}

const OrderInitialState: IOrder = {
  status: EOrderStatus.pending,
  header: headerInitialState,
  rows: [],
  rowsCount: 0,
  itemsCount: 0,
}

// Order reducers

export function initOrder(order = {}): IOrder {
  return _.defaultsDeep(order, OrderInitialState)
}

export function OrderAddFacilities(
  order: IOrder,
  facilities: IFacility[],
  priceTaxes = false,
): IOrder {
  return OrderUpdateAmounts(
    {
      ...order,
      rows: [
        ...order.rows,
        ...facilities.reduce((_rows, facility) => _rows.concat(OrderRowCreate(facility)), []),
      ],
    },
    priceTaxes,
  )
}

export function OrderRemoveRow(order: IOrder, row: IOrderRow): IOrder {
  return OrderUpdateAmounts({
    ...order,
    rows: rowRemove(order.rows, row),
  })
}

export function OrderUpdateAmounts(order: IOrder, priceTaxes = false): IOrder {
  const rows = order.rows.map(row => OrderRowUpdateAmounts(row, priceTaxes))
  return {
    ...order,
    rows,
    rowsCount: rows.length,
    itemsCount: rows.reduce((acc, row) => acc + row.orderedQty, 0),
    header: OrderHeaderUpdateAmounts(order.header, rows),
  }
}

// Header reducers

export function OrderHeaderUpdateAmounts(
  header: IOrderHeader,
  rows: IOrderRow[],
  priceTaxes = false,
): IOrderHeader {
  const subTotal = rows.reduce((acc, row) => acc + row.totalAmount, 0)
  const subTotalWithTaxes = rows.reduce(
    (acc, row) =>
      acc + (header.requestInvoice && row.isUntaxable ? row.totalAmount : row.totalAmountWithTaxes),
    0,
  )
  const bodyTaxes = rows.reduce(
    (acc, row) => acc + (header.requestInvoice && row.isUntaxable ? 0 : row.totalTaxes),
    0,
  )
  const totalDiscount = rows.reduce((acc, row) => acc + row.discount, 0)
  const totalDiscountWithTaxes = rows.reduce((acc, row) => acc + row.discountWithTaxes, 0)

  return {
    ...header,
    subTotal,
    subTotalWithTaxes,
    bodyTaxes,
    totalDiscount,
    totalDiscountWithTaxes,
    totalAmount: priceTaxes ? subTotalWithTaxes : subTotal + bodyTaxes,
  }
}

export function OrderHeaderSetCustomer(header: IOrderHeader, customer: ICustomer): IOrderHeader {
  return {
    ...header,
    customerId: customer._id,
    address: {
      name: customer.name,
      ...CustomerGetDefaultAddress(customer),
    },
    billingAddress: {
      name: customer.name,
      ...customer.billingAddress,
    },
  }
}

export function OrderHeaderUnsetCustomer(header: IOrderHeader): IOrderHeader {
  const { customerId, ..._header } = header
  return _header
}

// Rows reducers

export function OrderRowCreate(facility: IFacility, orderedQty = 1): IOrderRow {
  const { unitPrice, unitPriceWithTaxes, totalTaxes } = FacilityGetPrices(facility, orderedQty)

  return {
    facility: {
      _id: facility._id,
      SKU: facility.SKU,
      name: facility.name,
    },
    orderedQty: facility.orderedQty ? facility.orderedQty : orderedQty,
    unitPrice,
    unitPriceWithTaxes,
    taxRate: facility.price.taxRate,
    discount: 0,
    discountWithTaxes: 0,
    totalTaxes,
    totalAmount: unitPrice * orderedQty,
    totalAmountWithTaxes: unitPriceWithTaxes * orderedQty,
    isUntaxable: facility.price.isUntaxable,
  }
}

export function OrderRowUpdateAmounts(row: IOrderRow, priceTaxes = false): IOrderRow {
  const taxRate = !row.taxRate ? 0 : +row.taxRate
  const orderedQty = !row.orderedQty || row.orderedQty <= 0 ? 1 : +row.orderedQty
  const taxRatio = taxRate / 100 + 1

  let unitPriceWithTaxes = 0
  let unitPrice = 0
  let discountWithTaxes = 0
  let discount = 0

  if (priceTaxes) {
    unitPriceWithTaxes = !row.unitPriceWithTaxes ? 0 : +row.unitPriceWithTaxes
    unitPrice = unitPriceWithTaxes / taxRatio
    discountWithTaxes = !row.discountWithTaxes ? 0 : +row.discountWithTaxes
    discount = discountWithTaxes / taxRatio
  } else {
    unitPrice = !row.unitPrice ? 0 : +row.unitPrice
    unitPriceWithTaxes = unitPrice * taxRatio
    discount = !row.discount ? 0 : +row.discount
    discountWithTaxes = discount * taxRatio
  }

  return {
    ...row,
    unitPriceWithTaxes,
    unitPrice,
    discountWithTaxes,
    discount,
    totalTaxes: (unitPriceWithTaxes - unitPrice) * orderedQty,
    totalAmount: unitPrice * orderedQty - discount,
    totalAmountWithTaxes: unitPriceWithTaxes * orderedQty,
  }
}

export function rowAdd(rows: IOrderRow[], row: IOrderRow): IOrderRow[] {
  return [...rows, row]
}

export function rowRemove(rows: IOrderRow[], row: IOrderRow): IOrderRow[] {
  return rows.filter(_row => !_.isEqual(_row, row))
}

export function paymentAdd(payments: IOrderPayment[], payment: IOrderPayment): IOrderPayment[] {
  return [...payments, payment]
}

export function paymentRemove(payments: IOrderPayment[], payment: IOrderPayment): IOrderPayment[] {
  return payments.filter(pym => !_.isEqual(pym, payment))
}
