import * as _ from 'lodash'

import { IJob, IJobFacility, IFacility, ICustomer, IOrderFacility } from '../models'
import { CustomerGetDefaultAddress } from './customer.reducer'

// Initial states

const JobInitialState: IJob = {
  title: undefined,
  facilities: [],
  address: {},
  startedAt: new Date(),
  finishedAt: new Date(),
  isAllDay: false,
}

// Job reducers

export function initJob(job = {}): IJob {
  return _.defaultsDeep(job, JobInitialState)
}

export function JobAddFacility(job: IJob, facility: IFacility): IJob {
  const jobFacility = job.facilities.find(fac => fac._id === facility._id)

  return {
    ...job,
    facilities: jobFacility
      ? [
          ...job.facilities.filter(fac => fac._id !== facility._id),
          { ...jobFacility, qty: jobFacility.qty + 1 },
        ]
      : [...job.facilities, JobFacilityCreate(facility)],
  }
}

export function JobAddFacilities(job: IJob, facilities: IFacility[]): IJob {
  return {
    ...job,
    facilities: [
      ...job.facilities,
      ...facilities.reduce((_fclts, facility) => _fclts.concat(JobFacilityCreate(facility)), []),
    ],
  }
}

// Facilitites reducers

export function JobFacilityCreate(facility: IFacility | IOrderFacility, qty = 1): IJobFacility {
  return {
    _id: facility._id,
    SKU: facility.SKU,
    name: facility.name,
    qty,
  }
}

export function JobFacilityRemove(
  facilities: IJobFacility[],
  facility: IJobFacility,
): IJobFacility[] {
  return facilities.filter(fcl => !_.isEqual(fcl, facility))
}

// Customer reducers

export function JobSetCustomer(job: IJob, customer: ICustomer): IJob {
  return {
    ...job,
    customerId: customer._id,
    address: {
      name: customer.name,
      ...CustomerGetDefaultAddress(customer),
    },
  }
}

export function JobUnsetCustomer(job: IJob): IJob {
  const { customerId, ..._job } = job
  return _job
}
